<template>
  <div id="process-container">
    <Head />
    <div class="content" v-html="data.content"></div>
    <!-- <img class="process-img" src="@/assets/process-3.jpg" /> -->
    <!-- <img src="@/assets/process-2.jpg" /> -->
  </div>
</template>

<script>
import Head from "@/components/head";
export default {
  data() {
    return {
      id: 35,
      type: 2,
      data: {}
    };
  },
  components: {
    Head,
  },
  computed: {},
  methods: {
    getArticleDetail() {
      this.$api.articleDetail({id: this.id, type: this.type}).then((res) => {
        if (res.data.status) {
          this.data = res.data.data;
        }
      });
    }
  },
  created() {
    this.getArticleDetail();
  },
  mounted() {},
};
</script>

<style lang="scss">
#process-container {
  width: 100%;
  .content {
    overflow: hidden;
    margin-top: 200px;
    width: 100%;
    img {
      width: 120% !important;
      margin-left: -10% !important;
    }
  }
}
</style>
<style lang="scss" scoped>
#process-container {
  width: 100%;
  .content {
    width: 100%;
    img {
      width: 100% !important;
    }
  }
}
</style>